import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`값과 리터럴`}</h2>
    <p>{`프로그래밍을 하며 가장 많이 하는 일은 값(value)을 다루는 것입니다.`}</p>
    <p>{`프로그래밍 언어에서 값을 생성하는 가장 쉬운 방법은 리터럴(literal)을 사용하는 것입니다. 리터럴은 `}<strong parentName="p">{`값의 표기법`}</strong>{`으로, 프로그래밍 언어마다 값을 표현하는 여러 가지 리터럴을 가지고 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`1; // 정수 리터럴
2.5; // 부동 소수점 리터럴
'hello'; // 문자열 리터럴
true; // 진리값 리터럴
`}</code></pre>
    <p>{`리터럴과 연산자(operator)를 이용해 REPL에서 간단한 계산을 할 수 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`1 + 2; // 3
3 * 4; // 12
'hello' + 'world'; // 'helloworld'
true || false; // true
`}</code></pre>
    <p>{`여러 연산자에 대한 자세한 사용법은 이어지는 챕터에서 다룹니다.`}</p>
    <h2>{`변수 (Variable)`}</h2>
    <p>{`값을 한 번 생성한 뒤에 다시 쓰지 못한다면 아주 간단한 프로그램밖에 만들지 못할 것입니다. 그래서 프로그래밍 언어에는 대개 `}<strong parentName="p">{`값에 이름을 붙여서 다시 쓸 수 있게 만드는 기능`}</strong>{`이 존재합니다. JavaScript에서는 여러 가지 방법을 통해 값에 이름을 붙일 수 있는데, 그 중에 두 가지`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`를 여기서 다룹니다.`}</p>
    <p><inlineCode parentName="p">{`let`}</inlineCode>{`은 변수(variable)를 선언(declare)할 때 쓰는 키워드로, ES2015에서 도입되었습니다. 변수의 사용법은 다음과 같습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let seven = 7;
`}</code></pre>
    <p>{`위에서는 `}<inlineCode parentName="p">{`7`}</inlineCode>{`이라는 값에 `}<inlineCode parentName="p">{`seven`}</inlineCode>{`이라는 이름을 붙이기 위해서 다음과 같이 선언과 동시에 대입(assign)을 했습니다. 물론 변수의 선언이 끝난 이후에 대입을 하거나, 이미 값이 대입되어 있는 변수에 다른 값을 대입할 수도 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let eight;
eight = 8;
let seven = 7;
seven = 77;
seven = 777;
`}</code></pre>
    <p><inlineCode parentName="p">{`const`}</inlineCode>{`는 재대입(reassign)이 불가능한 변수를 선언할 때 쓰는 키워드로, 역시 ES2015에 도입되었습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const myConstant = 7;
`}</code></pre>
    <p><inlineCode parentName="p">{`const`}</inlineCode>{`로 변수를 선언할 때는 반드시 선언 시에 값을 대입해주어야 합니다. 값 없이 선언만 하게 되면 에러가 발생합니다. 또한 추후에 다른 값을 대입할 수 없습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const notAssigned; // Uncaught SyntaxError: Missing initializer in const declaration
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const assigned; = 1
assigned = 2; // Uncaught TypeError: Assignment to constant variable.
`}</code></pre>
    <p><inlineCode parentName="p">{`let`}</inlineCode>{`과 `}<inlineCode parentName="p">{`const`}</inlineCode>{` 모두 한꺼번에 여러 개의 변수를 선언하는 문법을 지원합니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let one = 1, two = 2, nothing;
const three = 3, four = 4;
`}</code></pre>
    <p><inlineCode parentName="p">{`let`}</inlineCode>{`과 `}<inlineCode parentName="p">{`const`}</inlineCode>{`로 선언한 이름은 다시 선언될 수 없습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let seven = 7;
let seven = 77; // Uncaught SyntaxError: Identifier 'seven' has already been declared
`}</code></pre>
    <h2><inlineCode parentName="h2">{`let`}</inlineCode>{`과 `}<inlineCode parentName="h2">{`const`}</inlineCode>{` 중 무엇을 쓸 것인가?`}</h2>
    <p>{`항상 `}<strong parentName="p">{`let 보다 const`}</strong>{`를 사용하는 것이 좋습니다. `}<inlineCode parentName="p">{`let`}</inlineCode>{`을 사용하면 의도치 않게 다른 값이 대입되어 버리는 일이 생길 수 있기 때문입니다. 정말로 재대입이 필요한 경우에만 `}<inlineCode parentName="p">{`let`}</inlineCode>{`을 사용하는 것이 좋은 습관입니다.`}</p>
    <h2>{`식별자`}</h2>
    <p>{`위에서 사용한 변수의 이름은 모두 `}<strong parentName="p">{`식별자(Identifier)`}</strong>{`입니다. 프로그래밍 언어에서 식별자는 어떤 개체를 유일하게 식별하기 위해 사용됩니다. JavaScript 식별자는 아래와 같이 정해진 규칙에 따라 지어져야 합니다.`}</p>
    <ul>
      <li parentName="ul">{`숫자, 알파벳, 달러 문자($), 언더스코어(_)가 포함될 수 있다.`}</li>
      <li parentName="ul">{`단, 숫자로 시작되어서는 안 된다.`}</li>
      <li parentName="ul">{`예약어는 식별자가 될 수 없다.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const foo; // O
const _bar123; // O
const $; // O - jQuery가 이 식별자를 사용합니다.
const 7seven; // X
const const; // X - 예약어는 식별자가 될 수 없습니다.
`}</code></pre>
    <p>{`여기서 예약어(reserved word)란 JavaScript 언어의 기능을 위해 미리 예약해둔 단어들을 말합니다. JavaScript의 예약어 목록을 `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Lexical_grammar#Reserved_keywords_as_of_ECMAScript_6"
      }}>{`이 링크`}</a>{`에서 확인할 수 있습니다.`}</p>
    <p><inlineCode parentName="p">{`let 패스트 = '캠퍼스'`}</inlineCode>{`와 같이 한글도 식별자에 포함될 수 있지만, 좋은 습관은 아닙니다.`}</p>
    <h3>{`Camel Case`}</h3>
    <p>{`식별자 이름을 지을 때 JavaScript에서 널리 사용되는 관례(convention)가 있는데, 이 관례를 Camel case라고 부릅니다. 식별자에 들어가는 각 단어의 첫 글자를 대문자로 써 주는 방식입니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Camel case
let fastCampus;
let fooBar;
`}</code></pre>
    <p>{`이와는 다르게 대문자를 사용하지 않고 단어 사이에 언더스코어(_)를 사용해서 단어를 구분해주는 관례도 있는데, 이를 Snake case라고 부릅니다. 이 관례는 JavaScript에서는 잘 사용되지 않고, Python 등의 프로그래밍 언어에서 많이 사용됩니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Snake case
let fast_campus;
let foo_bar;
`}</code></pre>
    <p>{`이 밖에 식별자 이름을 지을 때 사용하는 다른 관례들도 있는데, 그에 대해서는 `}<a parentName="p" {...{
        "href": "./180-object.md"
      }}>{`객체`}</a>{` 챕터에서 자세히 다룹니다.`}</p>
    <h2>{`타입`}</h2>
    <p>{`JavaScript를 비롯한 대부분의 프로그래밍 언어는 여러 가지 종류의 값을 지원하는데, 이러한 `}<strong parentName="p">{`값의 종류`}</strong>{`를 가지고 자료형(data type)이라고 부릅니다. 줄여서 `}<strong parentName="p">{`타입`}</strong>{`이라고 부르기도 합니다.`}</p>
    <p>{`값의 타입을 알아보기 위해 `}<inlineCode parentName="p">{`typeof`}</inlineCode>{` 연산자를 사용할 수 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`typeof 1; // 'number'
typeof 'hello'; // 'string'
`}</code></pre>
    <p>{`이어지는 챕터에서는 JavaScript가 지원하는 여러 가지 타입의 값의 사용법에 대해 다룰 것입니다.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`독자 중에 `}<inlineCode parentName="li">{`var`}</inlineCode>{` 키워드에 대해 알고 있는 분도 계실 것입니다. `}<inlineCode parentName="li">{`var`}</inlineCode>{`는 `}<inlineCode parentName="li">{`let`}</inlineCode>{`과 비슷한 역할을 하지만 동작하는 방식과 제약 사항이 다릅니다. 자세한 차이점은 `}<a parentName="li" {...{
            "href": "./220-value-in-depth.md"
          }}>{`값 더 알아보기`}</a>{` 챕터에서 다룹니다.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      